import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Leaves from '../components/icons/Approach/Leaves'
import GreenCheckMark from '../components/icons/GreenCheckMark'
import { Link, graphql } from "gatsby";
import { MicroCombo } from "../components/Trustpilot/TrustpilotWidgets";

import {
	Newsblock,
	NewsblockSection,
	NewsblockContent,
	NewsblockBlocks,
	NewsblockTitle,
} from '../components/Newsblock';

import "../../scss/main.scss";
import GroupTech from "../components/icons/Approach/GroupTech";
import CalendarDay from "../components/icons/Approach/CalendarDay";
import PieChart from "../components/icons/Approach/PieChart";
import TextDoc from '../components/icons/Approach/TextDoc'
import { PostcodeCTA } from '../components/PostcodeCTA/PostcodeCTA'


export default function Approach(props) {
	const [imageSelected, setImageSelected] = useState(0);

	const {
		data: {
			relatedPosts: { nodes: newsblockData },
			site: {
				siteMetadata: {
					featureToggles: {
						enableBlogContent,
					}
				}
			}
		}
	} = props


	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Our Approach</title>
				<link href="css/main.css" rel="stylesheet" />
				<meta property="og:title" content="Edyn Care | Our Approach" />
				<meta property="og:description" content="High quality care in the comfort of your own home." />
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
				<meta property="og:url" content="https://www.edyn.care/approach" />
				{/*Twitter links*/}
				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/approach" />
				<meta name="twitter:title" content="Edyn Care | Our Approach" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>
			<div className="container">
				<Header />
				<main className="approach-page">
					<section className="approach-grid">
						<div className="approach-post-landing">
							<StaticImage placeholder="blurred"
								className="approach-post-left"
								src="../../static/assets/marketing-site/images/our-approach-01.png"
								objectFit='contain'
							/>
							<div className="landing-content">
								<br />
								<p className="secondary">OUR APPROACH</p>
								<h1>
									Arranging care is complicated. <br />
									We’re here to help.
								</h1>
								<p className="primary">
									Starting with family care advice to managing complex
									conditions, we’re with you every step of the way.
								</p>
                <MicroCombo />
							</div>
							<StaticImage placeholder="blurred"
								className="approach-post-right"
								src="../../static/assets/marketing-site/images/our-approach-02.png"
								objectFit='contain'
							/>
						</div>
						<PostcodeCTA />
						{/* <div className="customer-quote">
							<div className="customer-quote-context">
								<div className="customer-img">
									<StaticImage
										src="../../static/assets/marketing-site/images/customer-img.png"
										objectFit='contain'
										placeholder="blurred"
										width={147}
									/>
								</div>
								<h3 className='approach-quote-1__main-copy'>
									“What makes Edyn stand out from other agencies we have used
									for live-in care is the fact that they care - the human touch
									is there. Our communications with them have always centred
									around a basic desire to do the best for my husband as an
									individual, not as a number.”
								</h3>
								<br />
								<div className="customer-names">
									<p className="secondary">
										Bernard S.
										<br />
										Father
									</p>
									<p className="secondary">
										James S.
										<br />
										Son
									</p>
								</div>
							</div>
						</div> */}
					</section>

					<section>
						<div className="approach-steps full-view">
							<div className="approach-steps-heading">
								<Leaves />
								<h2>
									We work with the whole family to <br/>
									get the best outcome for everyone.
								</h2>
								<p className="primary">
									Arranging care can feel overwhelming. Our care advisors are
									here to help you understand live-in care, what is involved and
									how it will work on a day-to-day basis.
								</p>
								<p>Here’s how care with edyn works:</p>
							</div>
							<div className="approach-steps-container">
								<div className="approach-step before-first-step">
									<div className="approach-step-left"> </div>
									<div className="approach-step-center">
										<span></span>
									</div>
									<div className="approach-step-right"> </div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-1.png" />
									</div>
									<div className="approach-step-center">
										<span>1</span>
									</div>
									<div className="approach-step-right">
										<h3>Getting to know you: </h3>
										<p className="primary">
											Your journey begins with a care consultation with one of
											our family care advisors and then a no-obligation care
											assessment from a care manager. They will learn what
											you’re hoping to achieve from your care, ensure that your
											property is safe and will share a digital care plan of
											your goals.
										</p>
										<Link to='/cta/'>
											<button className="btn-primary">
												Book your free care consultation
											</button>
										</Link>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-2.png" />
									</div>
									<div className="approach-step-center">
										<span>2</span>
									</div>
									<div className="approach-step-right">
										<h3>Matching your care team: </h3>
										<p className="primary">
											When you’re ready, we’ll send a tailored list of potential
											carers to you. We use smart technology to select carers
											based on experience, interests and location. Each carer
											has their own digital CV - you'll be able to see their
											care experience, their training, a biography and what
											they're interested in.
										</p>
										<div className="approach-steps-checklist">
											<div className="ap-checklist">
												<GreenCheckMark className="icon-default" />
												<p className="secondary">Trained By Us</p>
											</div>
											<div className="ap-checklist">
												<GreenCheckMark className="icon-default" />
												<p className="secondary">Insured</p>
											</div>
											<div className="ap-checklist">
												<GreenCheckMark className="icon-default" />
												<p className="secondary">DBS Check</p>
											</div>
										</div>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-3.png" />
									</div>
									<div className="approach-step-center">
										<span>3</span>
									</div>
									<div style={{paddingBottom: 70}} className="approach-step-right">
										<h3>Carer introduction:</h3>
										<p className="primary">
											Unlike other care companies, we believe in finding the
											right match which is why we facilitate introductory
											calls for our carers and clients, so they can get to know
											each other before any care starts.
										</p>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-4.png" />
									</div>
									<div className="approach-step-center">
										<span>4</span>
									</div>
									<div className="approach-step-right">
										<h3>Carer arrives:</h3>
										<p className="primary">
											We’ll arrange travel with the carer. They’ll arrive having
											read your care plan and understand your care requirements.
											Your family care coordinator will follow up that day to
											ensure everything is order.
										</p>
										{/*Empty div with the same height as the h3 title to keep symmetry*/}
										<div style={{ height: 77 }}></div>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-5.png" />
									</div>
									<div className="approach-step-center">
										<span>5</span>
									</div>
									<div className="approach-step-right">
										<h3>We manage your care: </h3>
										<p className="primary">
											Your dedicated care team will handle the progression of
											your care, liaising with your carers and family members to
											ensure all parties are aligned and working together.
										</p>
										{/*Empty div with the same height as the h3 title to keep symmetry*/}
										<div style={{ height: 77 }}></div>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-6.png" />
									</div>
									<div className="approach-step-center">
										<span>6</span>
									</div>
									<div className="approach-step-right">
										<h3>Your smart care: </h3>
										<p className="primary">
											Stay informed during your care with my edyn - a smart
											online portal which puts all your care information in one
											place. From updating payment details to reading the care
											plan and carer notes, we keep everyone updated and
											informed.
										</p>
										{/*Empty div with the same height as the h3 title to keep symmetry*/}
										<div style={{ height: 77 }}></div>
									</div>
								</div>
								<h3 className="last-step-text">And relax…</h3>
							</div>
						</div>
					</section>

					<section>
						<div className="approach-steps mobile">
							<div className="approach-steps-heading">
								<Leaves />
								<h2>
									We work with the whole family to <br />
									get the best outcome for everyone.
								</h2>
								<p className="primary">
									Arranging care can feel overwhelming. Our care advisors are
									here to help you understand live-in care, what is involved and
									how it will work on a day-to-day basis.
								</p>
								<p>Here’s how care with edyn works:</p>
							</div>
						</div>
					</section>
					<section>
						<div className="approach-steps-v2 mobile">
							<div className='approach-steps__circle-0'> </div>
							<div className='approach-steps__line approach-steps__line-0'> </div>
							<div className='approach-steps__circle approach-steps__circle-1'>1</div>
							<div className='approach-steps__line approach-steps__line-1'> </div>
							<div className='approach-steps__circle approach-steps__circle-2'>2</div>
							<div className='approach-steps__line approach-steps__line-2'> </div>
							<div className='approach-steps__circle approach-steps__circle-3'>3</div>
							<div className='approach-steps__line approach-steps__line-3'> </div>
							<div className='approach-steps__circle approach-steps__circle-4'>4</div>
							<div className='approach-steps__line approach-steps__line-4'> </div>
							<div className='approach-steps__circle approach-steps__circle-5'>5</div>
							<div className='approach-steps__line approach-steps__line-5'> </div>
							<div className='approach-steps__circle approach-steps__circle-6'>6</div>
							<div className='approach-steps__line approach-steps__line-6'> </div>
							<div className='approach-steps__section approach-steps__section-initial'>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/approach-steps-img-1.png"
								/>
							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-getting-to-know-you'>
								<h3>
									Getting to know you:
								</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-getting-to-know-you'>
								<p className="approach-steps__paragraph">
									Your journey begins with a care consultation with one of
									our family care advisors and then a no-obligation care
									assessment from a care manager. They will learn what
									you’re hoping to achieve from your care, ensure that your
									property is safe and will share a digital care plan of
									your goals.
								</p>
								<Link to='/cta/'>
									<button className="approach-steps__button">
										Book your free care consultation
									</button>
								</Link>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/approach-steps-img-2.png"
								/>
							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-matching-your-care-team'>
								<h3>
									Matching your care team:
								</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-matching-your-care-team'>
								<br />
								<p className="approach-steps__paragraph">
									When you’re ready, we’ll send a tailored list of potential
									carers to you. We use smart technology to select carers
									based on experience, interests and location. Each carer
									has their own digital CV - you'll be able to see their
									care experience, their training, a biography and what
									they're interested in.
								</p>
								<div className="approach-steps__checklist">
									<div className="approach-steps__checklist-item">
										<GreenCheckMark className="approach-steps__checklist-tick-icon" />
										<p className="secondary">Trained by us</p>
									</div>
									<div className="approach-steps__checklist-item">
										<GreenCheckMark className="approach-steps__checklist-tick-icon" />
										<p className="secondary">Insured</p>
									</div>
									<div className="approach-steps__checklist-item">
										<GreenCheckMark className="approach-steps__checklist-tick-icon" />
										<p className="secondary">DBS Check</p>
									</div>
								</div>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/approach-steps-img-3.png"
								/>
							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-carer-introduction'>
								<h3>
									Carer introduction:
								</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-carer-introduction'>
								<p className="approach-steps__paragraph">
									Unlike other care companies, we believe in finding the
									right match which is why we facilitate introductory
									calls for our carers and clients, so they can get to know
									each other before any care starts.
								</p>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/approach-steps-img-4.png"
								/>
							</div>

							<div className='approach-steps__subtitle approach-steps__subtitle-carer-arrives'>
								<h3>
									Carer arrives:
								</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-carer-arrives'>
								<p className="approach-steps__paragraph">
									We’ll arrange travel with the carer. They’ll arrive having
									read your care plan and understand your care requirements.
									Your family care coordinator will follow up that day to
									ensure everything is order.
								</p>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/approach-steps-img-5.png"
								/>
							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-we-manage-your-care'>
								<h3>
									We manage your care:
								</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-we-manage-your-care'>
								<p className="approach-steps__paragraph">
									Your dedicated care team will handle the progression of
									your care, liaising with your carers and family members to
									ensure all parties are aligned and working together.
								</p>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/approach-steps-image-6-mobile.png"
								/>

							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-your-smart-care'>
								<h3>
									Your smart care:
								</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-your-smart-care'>
								<p className="approach-steps__paragraph">
									Stay informed during your care with my edyn - a smart
									online portal which puts all your care information in one
									place. From updating payment details to reading the care
									plan and carer notes, we keep everyone updated and
									informed.
								</p>
								<div className="approach-steps__subtitle approach-steps__last-subtitle">
									<h3>
										And relax…
									</h3>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="approach-care">
							<div className="left-description">
								<h3 className="title">Exceptional care is not an individual job</h3>
								<div className="description">It takes a team working together to deliver our clients truly exceptional care. A Care Team consists of a group of specialists committed to the key areas of our client’s support.</div>
							</div>
							<div className="right-description">
								<div className="title">Our care teams consist of:</div>
								<div className="images-flex">
									<div className="image-description">
										<StaticImage src='../../static/assets/marketing-site/images/caretaker-img-01.png' objectFit="contain" />
										<h3 className="">A main and respite carer</h3>
										<p>They provide day-to-day support and help in your home.</p>
									</div>
									<div className="image-description">
										<StaticImage src='../../static/assets/marketing-site/images/caretaker-img-02.png' objectFit="contain"/>
										<h3>Family care coordinator</h3>
										<p>They will ensure you have the best carers matched to you providing the consistent help day in day out.</p>
									</div>
									<div className="image-description">
										<StaticImage src='../../static/assets/marketing-site/images/oli-replacement.png' objectFit="contain"/>
										<h3>Family Care Manager</h3>
										<p>They are here to ensure the care and support we provide is aligned to your desired outcomes and changing needs.</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="approach-consistent">
							<div className="approach-consistent-heading">
								<div className="approach-consistent-heading-left">
									<h1>We understand the importance of consistency</h1>
								</div>
								<div className="approach-consistent-heading-right">
									<p className="primary">
										For each of our clients we allocate two carers: Primary
										carer and Respite carer. They work in tandem to give you and
										your family the consistency you deserve.{" "}
									</p>
									<p className="primary">
										Our professional carers may be amazing, but they are only
										human. So as well as two hours breaks taken at a suitable
										time throughout the day, your primary live-in carer will
										also need to take time off.
									</p>
								</div>
							</div>
							<div className="approach-consistent-schedule">
								<div className="schedule-heading">
									<div className="schedule-heading-left">
										<h2>An example schedule</h2>
									</div>
									<div className="schedule-heading-right">
										<h2>Your carers:</h2>
										<div className="carer-heading carer">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/carer-olivia.png"
												alt=""
											/>
											<p>
												Main carer: <br />
												Olivia
											</p>
										</div>
										<div className="carer-heading carer green">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/carer-lyn.png"
												alt=""
											/>
											<p>
												Respite Carer: <br />
												Lyn
											</p>
										</div>
									</div>
								</div>
								<div className="approach-schedule-container">
									<div className="approach-schedule-left">
										<div className="approach-week week-trial">
											<p className="full-view">Trial Week</p>
											<p className="mobile-only">Week 1 (Trial)</p>
											<p>
												Your first week is a completely risk-free trial and
												Olivia, main carer, moves in.
											</p>
										</div>
										<div className="approach-week week-2-3">
											<p>Week 2-3</p>
											<p>If you’re happy, Olivia continues.</p>
										</div>
										<div className="approach-week week-4">
											<p>Week 4</p>
											<p>
												Olivia, your main carer, takes time off and your respite
												carer Lyn takes over
											</p>
										</div>
										<div className="approach-week week-5-7">
											<p>Weeks 5-7</p>
											<p>
												Olivia returns. They’ll typically be in place for
												another three weeks.
											</p>
										</div>
									</div>
									<StaticImage
										className="approach-schedule-lines"
										placeholder="blurred"
										src="../../static/assets/marketing-site/images/approach-infographic-lines.png"
										alt=""
										objectFit="fill"
									/>
									<div className="approach-schedule-right">
										<StaticImage
											placeholder="blurred"
											src="../../static/assets/marketing-site/images/approach-infographic.png"
											alt=""
											objectFit="contain"
											width={625}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
					<hr />

					<section>
						<div className="approach-tech">
							<div className="approach-tech__description">
								<h1 className="approach-tech__description-title">Technology to make us more human</h1>
								<p className="approach-tech__description-text">Edyn is one of the few live-in care companies with a dedicated software engineering team working on building our own software applications to enhance our client’s care directly.</p>
								<p className="approach-tech__description-text">Our portal gives our carers and families amazing visibility. Simple and transparent information on schedule, care plan, risk assessments, notes, wellness and payments.  </p>
								<div className="approach-tech__categories">
									{[
										<>
											<GroupTech className="gatsby-image-wrapper" />
											<p className="secondary">Your care team</p>
										</>,
										<>
											<CalendarDay className="gatsby-image-wrapper" />
											<p className="secondary">Visit summary</p>
										</>,
										<>
											<PieChart className="gatsby-image-wrapper" />
											<p className="secondary">Wellness report</p>
										</>,
										<>
											<TextDoc className="gatsby-image-wrapper" />
											<p className="secondary">Regular care statements</p>
										</>

									].map((element, index) =>
										<div
											className={`approach-tech-topic ${imageSelected === index ? "green" : ""}`}
											onClick={() => setImageSelected(index)}
										>
											{element}
										</div>
									)
									}
								</div>
							</div>
							{[
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-00.png" />,
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-01.png" />,
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-02.png" />,
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-03.png" />
							].map((element, index) =>
								index === imageSelected ? <div style={{display: "flex"}}>{element}</div> : null
							)}
						</div>
					</section>
					{enableBlogContent && (
						<>
							<NewsblockSection className="newsblock-latest">
								<NewsblockContent>
									<NewsblockTitle center displaySmall>
										More about how Edyn works
									</NewsblockTitle>
									<NewsblockBlocks>
										{newsblockData.map(({ title, slug }) => (
											<Newsblock
												displayShort
												color="yellow"
												title={title}
												link={`/blog/${slug}/`}>
											</Newsblock>
										))}
									</NewsblockBlocks>
								</NewsblockContent>
							</NewsblockSection>
						</>
					)}
				</main>
				<Footer />
			</div>
		</>
	);
}


export const pageQuery = graphql`
  	query ApproachQuery {
		site {
			siteMetadata {
				featureToggles {
					enableBlogContent
				}
			}
		}
		relatedPosts: allGhostPost(filter: {slug: {regex: "/cqc-vs-introductory-agency|what-is-a-care-assessment|what-is-a-care-consultation/"}}) {
			nodes {
			  	title
			  	slug
			}
		}
  	}
`