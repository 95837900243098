import React from 'react'

export default (props) => 
    <svg {...props} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M35.1328 21.4245C32.5064 20.519 29.7217 21.1072 27.5 22.7596C28.3407 25.4677 30.2404 27.6706 32.8672 28.5755C35.4936 29.481 38.2778 28.8928 40.5 27.2404C39.6593 24.5323 37.7592 22.3299 35.1328 21.4245Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.7233 36.9188C18.3481 39.7474 20.1844 41.9115 22.5467 43C24.1801 40.7905 24.9016 37.9097 24.2767 35.0807C23.6514 32.2522 21.8152 30.0885 19.4533 29C17.8199 31.2091 17.0984 34.0903 17.7233 36.9188Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.55102 8.56419C6.79388 10.5573 6.1833 13.2848 6.65195 15.932C9.16932 16.2549 11.6918 15.4287 13.449 13.4355C15.2061 11.4424 15.8167 8.71493 15.348 6.06817C12.8302 5.74479 10.3082 6.57107 8.55102 8.56419Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.6793 10.9795C41.2991 13.4038 41.1699 16.3544 42.0852 19C44.6096 18.8186 46.9407 17.4449 48.3205 15.0205C49.7007 12.5957 49.8303 9.64515 48.9146 7C46.3906 7.18144 44.0592 8.55469 42.6793 10.9795Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.1261 0.869978C32.7015 -0.362521 29.905 -0.23995 27.5 0.950137C27.9215 3.57715 29.4494 5.89752 31.8739 7.13002C34.2985 8.36252 37.095 8.23995 39.5 7.04986C39.078 4.42285 37.5506 2.10248 35.1261 0.869978Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.543 18.3169C27.5621 16.6433 28.5653 14.0521 28.4967 11.3592C26.074 10.6169 23.4757 11.0095 21.457 12.6827C19.4379 14.3563 18.4347 16.9474 18.5033 19.6408C20.926 20.3831 23.5239 19.9905 25.543 18.3169Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.69826 47.0453C7.08604 49.4697 9.4165 50.8338 11.9337 51C12.8329 48.3394 12.6892 45.3792 11.3018 42.9547C9.91448 40.5299 7.5836 39.1662 5.06683 39C4.1668 41.6606 4.3109 44.6208 5.69826 47.0453Z" fill="#0F2A2B"/>
        <mask id="mask0" maskType="alpha" maskUnits="userSpaceOnUse" x="0" y="22" width="12" height="9">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 22.2686H11.3548V30.8728H0.5V22.2686Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.01472 23.614C1.82176 25.1277 0.61264 27.6318 0.5 30.3173C2.94464 31.2374 5.64712 31.0415 7.84008 29.5274C10.0335 28.0137 11.2426 25.5095 11.3548 22.8241C8.91016 21.904 6.20768 22.0998 4.01472 23.614Z" fill="#0F2A2B"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.2372 59.4155C26.6613 60.4596 29.3162 60.0581 31.5 58.5845C30.8564 55.8931 29.1874 53.6289 26.7628 52.5848C24.3382 51.5402 21.6838 51.9422 19.5 53.4153C20.1436 56.1067 21.8126 58.3713 24.2372 59.4155Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.8965 21.4053C49.2703 22.2919 47.3588 24.4855 46.5 27.1939C48.7042 28.8687 51.4772 29.4812 54.1035 28.5947C56.7302 27.7081 58.6412 25.515 59.5 22.8061C57.2958 21.1313 54.5232 20.5188 51.8965 21.4053Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.0445 41.8111C50.0572 44.4376 52.3083 46.2684 55.0052 47C56.5219 44.6698 56.9678 41.8159 55.9555 39.1894C54.9432 36.5624 52.6917 34.7321 49.9948 34C48.4781 36.3306 48.0322 39.1845 49.0445 41.8111Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.0578 43.6236C40.6847 42.7667 42.6141 40.5884 43.5 37.88C41.3239 36.1698 38.5686 35.5194 35.9422 36.3764C33.3153 37.2329 31.3863 39.4111 30.5 42.12C32.6761 43.8302 35.4309 44.4806 38.0578 43.6236Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.7694 55.8921C46.7774 53.9733 47.6826 51.1342 47.4695 48.2524C44.9152 47.6074 42.239 48.1892 40.2309 50.108C38.2229 52.0263 37.3177 54.8658 37.5304 57.7477C40.0847 58.3927 42.7614 57.8104 44.7694 55.8921Z" fill="#0F2A2B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.5 16C36.5 17.1046 35.6049 18 34.5 18C33.3951 18 32.5 17.1046 32.5 16C32.5 14.8954 33.3951 14 34.5 14C35.6049 14 36.5 14.8954 36.5 16Z" fill="#EB5D48"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.5 7C24.5 8.10458 23.6045 9 22.5002 9C21.3955 9 20.5 8.10458 20.5 7C20.5 5.89542 21.3955 5 22.5002 5C23.6045 5 24.5 5.89542 24.5 7Z" fill="#EB5D48"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.5 34C32.5 35.1046 31.6045 36 30.4998 36C29.3955 36 28.5 35.1046 28.5 34C28.5 32.8954 29.3955 32 30.4998 32C31.6045 32 32.5 32.8954 32.5 34Z" fill="#EB5D48"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.5 25C19.5 26.1046 18.6049 27 17.5 27C16.3951 27 15.5 26.1046 15.5 25C15.5 23.8954 16.3951 23 17.5 23C18.6049 23 19.5 23.8954 19.5 25Z" fill="#EB5D48"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.5 35C13.5 36.1046 12.6045 37 11.5002 37C10.3955 37 9.5 36.1046 9.5 35C9.5 33.8954 10.3955 33 11.5002 33C12.6045 33 13.5 33.8954 13.5 35Z" fill="#EB5D48"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.5 32C45.5 33.1046 44.6045 34 43.5002 34C42.3955 34 41.5 33.1046 41.5 32C41.5 30.8954 42.3955 30 43.5002 30C44.6045 30 45.5 30.8954 45.5 32Z" fill="#EB5D48"/>
        <mask id="mask1" maskType="alpha" maskUnits="userSpaceOnUse" x="55" y="30" width="5" height="4">
            <path fillRule="evenodd" clipRule="evenodd" d="M55.8447 30.0979H59.3075V33.7699H55.8447V30.0979Z" fill="white"/>
        </mask>
        <g mask="url(#mask1)">
            <path fillRule="evenodd" clipRule="evenodd" d="M59.3075 31.9339C59.3075 32.9479 58.5323 33.7699 57.5763 33.7699C56.6199 33.7699 55.8447 32.9479 55.8447 31.9339C55.8447 30.9199 56.6199 30.0979 57.5763 30.0979C58.5323 30.0979 59.3075 30.9199 59.3075 31.9339Z" fill="#EB5D48"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.5 49C34.5 50.1046 33.6049 51 32.5 51C31.3951 51 30.5 50.1046 30.5 49C30.5 47.8954 31.3951 47 32.5 47C33.6049 47 34.5 47.8954 34.5 49Z" fill="#EB5D48"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.5 47C21.5 48.1046 20.6044 49 19.5 49C18.3951 49 17.5 48.1046 17.5 47C17.5 45.8954 18.3951 45 19.5 45C20.6044 45 21.5 45.8954 21.5 47Z" fill="#EB5D48"/>
    </svg>
